import { Link } from 'gatsby';
import React, { useEffect, useState } from "react";
import { isSafari } from 'react-device-detect';

import SEO from "../components/seo"
import Layout from "../components/layout"

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import "./nieuws.scss";
import nieuws from "../components/data/nieuws.json";

const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
}

const NieuwsPage = () => {
    let blogArticles = nieuws.blogArticles;
    let metaDescriptions = nieuws.metaDescriptions;
    let titles = nieuws.titles;
    let [articles, setArticles] = useState(null);

    let metaDescriptionValue = '';
    let content = null;
    let nieuwsbriefTitle = '';
    let nieuwsbriefSubtitle = '';

    if (titles.length > 0) {
        for (const title of titles) {
            if (title.indicator === 'nieuws-content' && title.richValue && title.richValue.html) {
                content = <div className="html-text" dangerouslySetInnerHTML={{ __html: nl2br(title.richValue.html) }} />;
            }
            if (title.indicator === 'nieuwsbrief-title' && title.value) {
                nieuwsbriefTitle = title.value;
            }
            if (title.indicator === 'nieuwsbrief-subtitle' && title.value) {
                nieuwsbriefSubtitle = title.value;
            }
        }
    }
    if (metaDescriptions.length > 0) {
        for (const metaDescription of metaDescriptions) {
            if (metaDescription.indicator === 'nieuws-meta-description') {
                metaDescriptionValue = metaDescription.value;
            }
        }
    }

    useEffect(() => {
        if (isSafari === true) {
            setArticles(blogArticles.map((article) => {
                return (
                    <div key={article.slug + '-article'} className="col-md-3">
                        <Link className="article-link" key={article.slug + '-link'} to={`/nieuws/${article.slug}`}>
                            <img src={article.blogHeaderImage} alt="" loading="lazy" />
                            <div className="text-container">
                                <p className="date-time">
                                    {new Intl.DateTimeFormat("en-GB", {
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit",
                                        hour: 'numeric', minute: 'numeric', second: 'numeric',
                                        hour12: false
                                    }).format(new Date(article.publishDateTime))}
                                </p>
                                <p className="type-article">{article.blogType}</p>
                                {article.readTimeMinutes != null && <p className="read-time">Leestijd: {article.readTimeMinutes} minuten</p>}
                                <h3>{article.blogTitle}</h3>
                                <p>{article.blogShortDescription}</p>
                                <Link className="read-more" key={article.slug} to={`/nieuws/${article.slug}`}>lees meer
                                    <div className="icon baseline">
                                        <ArrowRightAltIcon />
                                    </div>
                                </Link>
                            </div>
                        </Link>
                    </div>
                );
            }));
        } else {
            setArticles(blogArticles.map((article) => {
                return (
                    <div key={article.slug + '-article'} className="col-md-3">
                        <Link className="article-link" key={article.slug + '-link'} to={`/nieuws/${article.slug}`}>
                            <img src={article.blogHeaderImage} alt="" loading="lazy" />
                            <div className="text-container">
                                <p className="date-time">
                                    {new Intl.DateTimeFormat("nl-nl", {
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit"
                                    }).format(new Date(article.publishDateTime))}
                                </p>
                                <p className="type-article">{article.blogType}</p>
                                {article.readTimeMinutes != null && <p className="read-time">Leestijd: {article.readTimeMinutes} {article.readTimeMinutes === 1 ? 'minuut' : 'minuten'}</p>}
                                <h3>{article.blogTitle}</h3>
                                <p>{article.blogShortDescription}</p>
                                <Link className="read-more" key={article.slug} to={`/nieuws/${article.slug}`}>lees meer
                                    <div className="icon baseline">
                                        <ArrowRightAltIcon />
                                    </div>
                                </Link>
                            </div>
                        </Link>
                    </div>
                );
            }));
        }
    }, [blogArticles]);

    return (
        <Layout>
            <SEO title="Nieuws" description={metaDescriptionValue} urlParams="nieuws" />
            <img className="print print-image" src={isSafari === true ? 'https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' : 'https://media.travelspirit.nl/original/smw/TravelSpirit_Logo-FC.webp'} alt="logo" loading="lazy" />
            <div className="nieuws-container container">
                <div className="inner-container">
                    {content}
                    <div className="blogs-container container">
                        <div className="row">
                            {articles}
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-container">
                <h2>{nieuwsbriefTitle}</h2>
                <p>{nieuwsbriefSubtitle}</p>
                {/* <div id='mpform1102' ></div> */}
                <form id="form1102" encType="application/x-www-form-urlencoded" className="mpForm" method="post" action="https://m13.mailplus.nl/genericservice/code/servlet/Redirect">
                    <ul className="mpFormTable mpTwoColumnLayout">
                        <li id="CNT1156" className="mpQuestionTable  ">
                            <div className="mpFormLabel">
                                <label className="descriptionLabel" htmlFor="field1156">E-mailadres</label>
                                <span className="mandatorySign"> *</span>
                            </div>
                            <div className="mpFormField">
                                <input type="text" id="field1156" name="field1156"></input>
                                <div className="sublabel">Vul hier je e-mailadres in naam@bedrijf.nl</div>
                            </div>
                        </li>
                        <li id="CNT1162" className="mpQuestionTable">
                            <div className="mpLabelRow">
                                <div className="submitCellSpacer">
                                    <span></span>
                                </div>
                                <div className="submitCell">
                                    <input value="Verzenden" className="submitButton" name="next" type="submit" id="field1162"></input>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <fieldset style={{ display: 'none' }}>
                        <input type="hidden" name="userId" value="2110488"></input>
                        <input type="hidden" name="formEncId" value="RpPvABh6UMD4wG9y9VHG"></input>
                        <input type="hidden" name="pagePosition" value="1"></input>
                        <input type="hidden" name="viewMode" value="STATICINTEGRATION"></input>
                        <input type="hidden" name="redir" value="formAdmin2"></input>
                        <input type="hidden" name="formLayout" value="N"></input>
                        <input type="hidden" name="errorText" value="Dit formulier kon niet verzonden worden om de volgende reden(en):"></input>
                    </fieldset>
                </form>
            </div>
        </Layout>
    )
}

export default NieuwsPage;
